<template>
  <div>
    <v-app-bar>
      <v-toolbar-title>Adrian Göß</v-toolbar-title>
    </v-app-bar>
    <v-main style="padding-bottom: 9rem">
      <v-container>
        <v-row class="pt-10" >
          <v-col cols="12" md="4">
            <div class="d-flex flex-column justify-center align-center">
              <v-sheet width="250px" elevation="4">
                <v-img
                  :src="require('../assets/adrian.jpg')"
                  alt="Adrian_Goess"
                ></v-img>
              </v-sheet>
              <h2 class="mt-4">Adrian Göß</h2>
              <p class="mt-2">Doctoral Candidate</p>
              <div class="text-center">
                <v-btn
                  class="mt-2"
                  height="48"
                  target="_blank"
                  href="https://www.utn.de/person/adrian-goess/"
                  color="primary"
                  ><span>University of Technology <br/> Nuremberg (UTN)</span></v-btn>
              </div>
              <v-row class='mt-2'>
                <v-btn
                  target="_blank"
                  href="https://www.linkedin.com/in/adrian-g%C3%B6%C3%9F-0a5b27175/"
                  class="ma-2"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-linkedin</v-icon>
                </v-btn>
                <v-btn
                  target="_blank"
                  href="https://www.instagram.com/adrian_goess/"
                  class="ma-2"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <v-btn
                  target="_blank"
                  href="mailto:adrian.goess@utn.de"
                  class="ma-2"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-email</v-icon>
                </v-btn>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12" md="8">
            <h2>Quick Info</h2>
            <p>
              I am a research associate and doctoral candidate at the 
              <a target="_blank" class='text-decoration-none text-primary' href="https://www.utn.de/en/departments/department-liberal-arts-and-sciences/analytics-and-optimization-lab/"
              >Analytics & Optimization Lab</a>
              at
              <a target="_blank" class='text-decoration-none text-primary' href="https://www.utn.de/en/"
                >University of Technology Nuremberg (UTN)</a
              >.
              Before following my supervisor Prof. Dr. Alexander Martin to UTN, I've had identical roles at the chair for Analytics & Mixed-integer Optimization at 
              <a target="_blank" class='text-decoration-none text-primary' href="https://www.fau.eu/" 
                >Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU)</a>.
              
            </p>
            <h2 class="mt-4"> Research Interests </h2>
            <p>
              My research is primarily funded by the collaborative research center (CRC)
              <a target="_blank" class='text-decoration-none text-primary' 
              href='https://www.trr154.fau.de/trr-154-en/'>
              SFB TRR-154,
              </a> which is in its third and final phase. 
              Sponsored by the German Research Foundation (DFG), TRR-154 aims 
              for developing methods to supply gas efficiently, i.e., for optimal gas network control. 
              Sub-project A05, which I am involved in, treats the incorporation of machine learning 
              algorithms in order to enhance standard mathematical optimization methods.
              <br/>
              This motivates my interest in Mixed-Integer Nonlinear Programming (MINLP), 
              especially when the constraints are nonconvex and/or some Lipschitz 
              condition is known for them.
              Therefore, my research also touches on the areas of Lipschitz and Black-Box Optimization.
              
            </p>
            <h2 class="mt-4"> Curriculum Vitae </h2>
            <p>
              In terms of academic achievements, I hold an undergraduate degree
              in Mathematics & Economics, as well as a Master of Science in 
              Mathematics with Computer Science as minor; both from FAU.
              <br/> 
              In the CRC, I hold the position of the Erlangen/Nürnberg representative in the 
              young researchers council.
              At UTN, from October 2024, I am an elected representative for the scientific staff 
              in the founding committee.
              <br />
              During my master studies I joined the 
              <a target="_blank" class='text-decoration-none text-primary'
              href='https://www.jct.de'> 
              Junior Consulting Team e.V. (JCT)
              </a>,
              a student consultancy in Erlangen-Nürnberg. For one year,
              in 2021/22,
              I was member of the board of JCT and in charge of Finance & Legal.
              Currently, I am the co-projekt lead for a semi-annual conference, financed by JCT's 
              umbrella organisation the Bund Deutscher Studentischer Unternehmensberatungen e.V. 
              (BDSU, eng: Association of German Student Consultancies).

            </p>
          </v-col>
        </v-row>

        <v-row class="pa-6 mt-10" style="background-color: #E0E0E0">
          <v-col>
            <h2 class="mt-1">Working Papers</h2>
            <ul class="mt-2">
              <li>
                <h3>
                  Complexity of Integer Programming in Reverse Convex Sets via Boundary Hyperplane Cover
                  <a target="_blank" href="https://arxiv.org/abs/2409.05308">[arXiv]</a>
                </h3>
                <p>
                  Robert Hildebrand, Adrian Göß
                  <br/>
                  arXiv: 2409.05308, September 2024
                </p>
              </li>
              <li>
                <h3>
                  All You Need is a Paraboloid: Quadratic Cuts for Non-Convex MINLP
                  <a target="_blank" href="https://arxiv.org/abs/2407.06143">[arXiv]</a>
                </h3>
                <p>
                  Adrian Göß, Robert Burlacu, Alexander Martin
                  <br/>
                  arXiv: 2407.06143, June 2024
                </p>
              </li>
              <li>
                <h3>
                  Exploiting user-supplied Decompositions inside Heuristics 
                  <a target="_blank" href="https://optimization-online.org/?p=23386">[optimization-online]</a>
                </h3>
                <p>
                  Katrin Halbig, Adrian Göß, Dieter Weninger
                  <br/>
                  Optimization Online: 23386, May 2024
                </p>
              </li>
              <li>
                <h3>
                  Norm-induced Cuts: Optimization with Lipschitzian Black-box
                  Functions <a target="_blank" href="https://arxiv.org/abs/2403.11546">[arXiv]</a> <!-- [bibtex] -->
                </h3>
                <p>
                  Adrian Göß, Alexander Martin, Sebastian Pokutta, Kartikey
                  Sharma 
                  <br/>
                  arXiv: 2403.11546, March 2024 (Latest Update: September 2024)
                </p>
              </li>
            </ul>

            <h2>Theses</h2>
            <ul class="mt-2">
              <li>
                <h3>
                  [Master thesis] Gas Network Control by Simulation-based 
                  Reinforcement Learning
                </h3>
                <p>
                  Adrian Göß; Supervisors: Dr. Uwe Gotzes, Dr. Klaus Spreckelsen,
                  Prof. Dr. Frauke Liers, Dr. Robert Burlacu, Dr. Johannes Thuerauf,
                  Kristin Braun; November 2021
                </p>
              </li>
              <li>
                <h3>
                  [Bachelor thesis] Globale Optimierung mittels Geometric Programming:
                  Theorie, Verallgemeinerung und numerische Anwendung <br />
                  (English: Global Optimization using Geometric Programming:
                  Theory, Generalization and Numerical Application)
                </h3>
                <p>
                  Adrian Göß; Supervisor: Prof. Dr. Wolfgang Achtziger; September 2019
                </p>
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-row class="pa-6 mt-10">
          <v-col>
            <h2>Teaching</h2>
            <ul class="mt-2">
              <li>
                <h3>
                  Discrete Optimization II [FAU, SS23]
                </h3>
                <p>
                  Prof. Dr. Timm Oertel, Adrian Göß
                </p>
              </li>
              <li>
                <h3>
                  Discrete Optimization III [FAU, WS22/23]
                </h3>
                <p>
                  Prof. Dr. Alexander Martin, Adrian Göß
                </p>
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-row class="pa-6 mt-10" style="background-color: #E0E0E0">
          <v-col>
            <h2 class="mt-1">Contact</h2>
            <p class="mt-3">
              <v-icon class="mr-2">mdi-email</v-icon>adrian.goess@utn.de
            </p>
            <p class="mt-3">
              <v-icon class="mr-2">mdi-map-marker</v-icon>Ulmenstraße 52i, 90443 Nürnberg
            </p>
            <p class="mt-3">
              <v-icon class="mr-2">mdi-calendar-check</v-icon>send email for
              appointment
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style>
  p {
    font-size: 15pt;
  }
  h2 {
    font-size: 20pt;
  }
  h3 {
    font-size: 16pt;
  }
</style>